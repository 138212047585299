import React, { Component } from "react";
import Form from "./form";
import ArticleImage from "../articleImage";
import TheatreArticleImage from "../theatreArticleImage";

class ArticleForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backToForm: false
        }

        this.handleBackButton = this.handleBackButton.bind(this);
    }

    handleBackButton(e) {
        e.preventDefault();

        this.setState({ backToForm: true });
    }

    render() {
        const { backToForm } = this.state;
        const { person } = this.props;
        const isDavidCartwright = person.toLowerCase().includes('cartwright');

        if (!backToForm) {
            return (
                <>
                    <div className="form--results">
                        <h2>Search Archive</h2>
                        <header>
                            <div className="align--right"><button aria-label="Back" onClick={(e) => this.handleBackButton(e)}>&lt; Home</button></div>
                            <h3 className="align--left">Search / {person}</h3>
                        </header>
                        <p className="italic">1 entry</p>
                        {isDavidCartwright && <ArticleImage />}
                        {!isDavidCartwright && <TheatreArticleImage />}
                    </div>
                </>
            )
        } else {
            return (
                <Form />
            )
        }
    }
}

export default ArticleForm;
