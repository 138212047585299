import React, { Component } from "react";
import TheatreForm from "./theatreForm";
import ArticleImage from "../theatreArticleImage";

class TheatreArticleForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: "archive",
            fields: {
                theatreName: '',
                address: '',
                yearConstructed: ''
            },
            errors: {},
            correctFields: {
                theatreName: false,
                address: false,
                yearConstructed: false
            },
            articleFormValid: false
        };

        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
    }

    handleTabChange(tab) {
        this.setState({ activeTab: tab });
    }

    handleBackButton(e) {
        e.preventDefault();
        window.location.reload();
    }

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        let fields = this.state.fields;

        fields[name] = value;
        this.setState({ fields }, () => { this.handleValidation(name, value) });
    }

    handleFormSubmit(e) {
        e.preventDefault();

        if (this.handleValidation('theatreName', this.state.fields.theatreName) &&
            this.handleValidation('address', this.state.fields.address) &&
            this.handleValidation('yearConstructed', this.state.fields.yearConstructed)) {
            this.setState({ articleFormValid: true });
            return true;
        } else {
            this.setState({ articleFormValid: false });
            return false;
        }
    }

    // Define arrays of correct answers
    correctAnswers = {
        theatreName: ['Egyptian Hall', 'egyptian hall', 'EGYPTIAN HALL', 'Egyptian hall', 'egyptian Hall', 'Egyptian hall', 'egyptian Hall'],
        address: ['170 Piccadilly', '170 piccadilly', '170 PICCADILLY', '170picadilly'],
        yearConstructed: ['1817']
    };

    // Helper function to remove punctuation
    removePunctuation(str) {
        return str.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").replace(/\s{2,}/g, " ").toLowerCase();
    }

    handleValidation(name, value) {
        let fields = this.state.fields;
        let errors = this.state.errors;
        let correctFields = this.state.correctFields;
        let formIsValid = true;

        // Theatre Name
        if (name === "theatreName") {
            if (!fields["theatreName"]) {
                formIsValid = false;
                errors["theatreName"] = "Theatre Name cannot be empty";
                correctFields["theatreName"] = false;
            } else if (!this.correctAnswers.theatreName.map(this.removePunctuation).includes(this.removePunctuation(fields["theatreName"]))) {
                formIsValid = false;
                errors["theatreName"] = "Incorrect Theatre Name";
                correctFields["theatreName"] = false;
            } else {
                correctFields["theatreName"] = true;
                delete errors["theatreName"];
            }
        }

        // Address
        if (name === "address") {
            if (!fields["address"]) {
                formIsValid = false;
                errors["address"] = "Address cannot be empty";
                correctFields["address"] = false;
            } else if (!this.correctAnswers.address.map(this.removePunctuation).includes(this.removePunctuation(fields["address"]))) {
                formIsValid = false;
                errors["address"] = "Incorrect Address";
                correctFields["address"] = false;
            } else {
                correctFields["address"] = true;
                delete errors["address"];
            }
        }

        // Year Constructed
        if (name === "yearConstructed") {
            if (!fields["yearConstructed"]) {
                formIsValid = false;
                errors["yearConstructed"] = "Year Constructed cannot be empty";
                correctFields["yearConstructed"] = false;
            } else if (!/^\d{4}$/.test(fields["yearConstructed"])) {
                formIsValid = false;
                errors["yearConstructed"] = "Year Constructed must be a valid year (e.g., 1599)";
                correctFields["yearConstructed"] = false;
            } else if (!this.correctAnswers.yearConstructed.includes(fields["yearConstructed"])) {
                formIsValid = false;
                errors["yearConstructed"] = "Incorrect Year Constructed";
                correctFields["yearConstructed"] = false;
            } else {
                correctFields["yearConstructed"] = true;
                delete errors["yearConstructed"];
            }
        }

        this.setState({ errors: errors, correctFields: correctFields });
        return formIsValid;
    }

    render() {
        const { activeTab, fields, errors, correctFields, articleFormValid } = this.state;

        return (
            <>
                <div className="tabs">
                    <button 
                        className={activeTab === "archive" ? "active" : ""}
                        onClick={() => this.handleTabChange("archive")}>
                        Search Archive
                    </button>
                    <button 
                        className={activeTab === "theatre" ? "active" : ""}
                        onClick={() => this.handleTabChange("theatre")}>
                        Search London Theatre Archive
                    </button>
                </div>

                {activeTab === "archive" && (
                    <div className="form--results">
                        <header>
                            <div className="align--right">
                                <button aria-label="Back" onClick={this.handleBackButton}>&lt; Home</button>
                            </div>
                            <h3 className="align--left">Search / Richard Cane</h3>
                        </header>
                        <p className="italic">1 entry</p>
                        <ArticleImage />
                    </div>
                )}

                {activeTab === "theatre" && (
                    <TheatreForm
                        fields={fields}
                        errors={errors}
                        correctFields={correctFields}
                        articleFormValid={articleFormValid}
                        handleUserInput={this.handleUserInput}
                        handleFormSubmit={this.handleFormSubmit}
                        handleValidation={this.handleValidation}
                        handleBackButton={this.handleBackButton}
                    />
                )}
            </>
        );
    }
}

export default TheatreArticleForm;
