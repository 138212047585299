import React from "react";
import TheatreFormValid from './theatreFormValid';
import "../../assets/styles/components/form.css";
import Tick from "../../assets/images/tick.svg";
import Cross from "../../assets/images/cross.svg";

class TheatreForm extends React.Component {
    handleBackButton(e) {
        e.preventDefault();
        window.location.reload();
    }
    
    render() {
        const { fields, errors, correctFields, articleFormValid, handleUserInput, handleFormSubmit, handleBackButton } = this.props;

        if (!articleFormValid) {
            return (
                <>
                    <div className="form--results theatre--form">
                        <header>    
                            <div className="align--right">
                            <button aria-label="Back" onClick={this.handleBackButton}>&lt; Home</button>
                            </div>
                            <h3 className="align--left">Search for British Theatres 1674 - 1951</h3>
                        </header>
                        <form className={`form ${!articleFormValid ? 'has-error' : ''}`} onSubmit={handleFormSubmit} noValidate>
                            <div className="form-group">
                                <label htmlFor="theatreName">Theatre Name:</label>
                                <input type="text" required className="form-control" name="theatreName" aria-label="theatreName" onChange={handleUserInput} value={fields.theatreName} />
                                {errors["theatreName"] && <span className="error"><Cross />{errors["theatreName"]}</span>}
                                {correctFields["theatreName"] && <span className="correct"><Tick />Correct</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="address">Address (1st Line):</label>
                                <input type="text" required className="form-control" name="address" aria-label="address" onChange={handleUserInput} value={fields.address} />
                                {errors["address"] && <span className="error"><Cross />{errors["address"]}</span>}
                                {correctFields["address"] && <span className="correct"><Tick />Correct</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="yearConstructed">Year Constructed:</label>
                                <input type="text" required className="form-control" name="yearConstructed" aria-label="yearConstructed" onChange={handleUserInput} value={fields.yearConstructed} />
                                {errors["yearConstructed"] && <span className="error"><Cross />{errors["yearConstructed"]}</span>}
                                {correctFields["yearConstructed"] && <span className="correct"><Tick />Correct</span>}
                            </div>
                            <div className="form-actions">
                                <button type="submit" className="button">Search</button>
                            </div>
                        </form>
                    </div>
                </>
            );
        } else {
            return (
                <TheatreFormValid />
            );
        }
    }
}

export default TheatreForm;
