import React, { Component } from "react";
import TheatreImage from "../theatreImage";
import "../../assets/styles/components/form.css";

class TheatreFormValid extends Component {
    constructor(props) {
        super(props);
        this.handleBackButton = this.handleBackButton.bind(this);
    }

    handleBackButton(e) {
        e.preventDefault();
        window.location.reload();
    }

    render() {
        return (
            <>             
                <div className="form--results">
                    <header>
                        <div className="align--right">
                            <button aria-label="Back" onClick={this.handleBackButton}>&lt; Home</button>
                        </div>
                        <h3 className="align--left">Search / Egyptian Hall</h3>
                    </header>
                    <p className="italic">1 entry</p>
                    <TheatreImage />
                </div>
            </>
        );
    }
}

export default TheatreFormValid;
