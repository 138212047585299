import React, { Component } from "react";
import FormErrors from './formErrors';
import ArticleForm from './articleForm';
import TheatreArticleForm from './theatreArticleForm';

import HomepageImage1 from "../../assets/images/homepage-image1.jpg";
import HomepageImage2 from "../../assets/images/homepage-image2.jpg";

import "../../assets/styles/components/form.css";

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {
                personOfInterest: '',
                theatreName: '',
                address: '',
                yearConstructed: ''
            },
            errors: {},
            correctFields: {},
            formValid: false,
            person: '',
            articleFormValid: false
        }

        this.handleUserInput = this.handleUserInput.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleValidation(name, value) {
        const correctPeople = ['David P. Cartwright', 'David P Cartwright', 'David Cartwright', 'david cartwright', 'DAVID CARTWRIGHT', 'Richard Cane', 'richard cane', 'RICHARD CANE', 'richard Cane', 'Richard cane', 'richardcane'];
        const fields = this.state.fields;
        let errors = this.state.errors;
        let correctFields = this.state.correctFields;
        let formIsValid = true;

        if (name === 'personOfInterest' && !correctPeople.includes(value)) {
            formIsValid = false;
            errors[name] = "No records match this name";
            correctFields[name] = false;
        } else {
            correctFields[name] = true;
            delete errors[name];
        }

        this.setState({ errors, correctFields });

        return formIsValid;
    }

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        let fields = this.state.fields;

        fields[name] = value;
        this.setState({ fields });
    }

    handleFormSubmit(e) {
        e.preventDefault();

        if (this.handleValidation('personOfInterest', this.state.fields.personOfInterest)) {
            this.setState({ formValid: true, person: this.state.fields.personOfInterest });
            return true;
        } else {
            this.setState({ formValid: false });
            return false;
        }
    }

    render() {
        const { formValid, person, fields, errors, correctFields } = this.state;
        const isDavidCartwright = person.toLowerCase().includes('cartwright');
        const isRichardCane = person.toLowerCase().includes('cane');

        if (!formValid) {
            return (
                <>
                <div className="images">
                    <img src={HomepageImage1} alt="Home 1" />
                    <img src={HomepageImage2} alt="Home 2" />
                </div>
                <div className="text">
                    <h2>Welcome</h2>
                    <p>Welcome to the archive of the Morning Gazette Newspaper, which was founded in 1674 and ceased printing in 1951. Please use the search function below to look through our records. We have a full selection of articles on this online resource but if you should fail to find what you are looking for please feel free to visit our London archive in person, or write to us at our head office.</p>
                </div>
                    <form className="form" onSubmit={this.handleFormSubmit}>
                        <h2 className="tab">Search Archive</h2>
                        <div className="form-group">
                            <label htmlFor="personOfInterest">Person of Interest:</label>
                            <input type="text" required className="form-control" name="personOfInterest" aria-label="personOfInterest" onChange={this.handleUserInput} value={fields.personOfInterest} />
                            {errors["personOfInterest"] && <span className="error-message">{errors["personOfInterest"]}</span>}
                        </div>
                        <div className="form-actions">
                            <button type="submit" className="button">Search</button>
                            <FormErrors formErrors={this.state.errors} />
                        </div>
                    </form>
                </>
            )
        } else {
            return (
                <>
                    {isDavidCartwright && <ArticleForm person="David P. Cartwright" />}
                    {isRichardCane && <TheatreArticleForm
                        fields={fields}
                        errors={errors}
                        correctFields={correctFields}
                        articleFormValid={this.state.articleFormValid}
                        handleUserInput={this.handleUserInput}
                        handleFormSubmit={this.handleFormSubmit}
                        handleBackButton={this.handleBackButton}
                    />}
                </>
            )
        }
    }
}

export default Form;
