import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import richardImageDesktop from '../assets/images/richard-cane-desktop.jpg';
import richardImageMobile from '../assets/images/richard-cane-mobile.jpg';

import 'react-image-lightbox/style.css';

const ArticleImage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(richardImageDesktop);

  const updateImageSrc = () => {
    if (window.innerWidth < 980) {
      setImageSrc(richardImageMobile);
    } else {
      setImageSrc(richardImageDesktop);
    }
  };

  useEffect(() => {
    updateImageSrc();
    window.addEventListener('resize', updateImageSrc);
    return () => {
      window.removeEventListener('resize', updateImageSrc);
    };
  }, []);

  return (
    <figure className="image">
      <button type="button" onClick={() => setIsOpen(true)}>
        <img src={imageSrc} alt="Richard Cane" />
      </button>

      {isOpen && (
        <Lightbox
          mainSrc={imageSrc}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
    </figure>
  );
};

export default ArticleImage;
